import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import logo from "../../image/imi.png";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import PublishIcon from "@mui/icons-material/Publish";
import { useLocation, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { homeActions } from "../../store/Store";
import Keycloak from "keycloak-js";

const CustomAppBar = () => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    root: {},
    appBar: {
      backgroundColor: "rgb(45,55,72)",
    },
    toolbar: {},
    logo: {},
    title: {},
  }));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  let location = useLocation();
  const [show, setShow] = useState(false);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/customize") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [location]);

  const dispatch = useDispatch();
  const logout = () => {
    //dispatch(homeActions.change_IsLoggedIn(false));
    //keycloak.logout({ redirectUri: "http://localhost:3000/login" });
    keycloak.logout();
    //console.log(keycloak.authenticated);
  };
  //#endregion

  return (
    <AppBar
      position="static"
      elevation={0}
      style={{ background: "rgb(45,55,72)", overflow: "hidden" }}
    >
      <Toolbar>
        <IconButton
          style={{ padding: "0px", margin: "0px", overflow: "hidden" }}
        >
          <img
            src={logo}
            alt="Game Maker Logo"
            style={{ width: "50%", height: "25%" }}
          />
        </IconButton>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          Game Maker
        </Typography>
        {show && (
          <Button startIcon={<HomeIcon />} color="inherit" variant="h6">
            Home
          </Button>
        )}
        {show && (
          <Button startIcon={<PublishIcon />} color="inherit" variant="h6">
            Publish
          </Button>
        )}

        <Button
          onClick={logout}
          startIcon={<LogoutIcon />}
          color="inherit"
          variant="h6"
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
