import { Button, Grid } from "@mui/material";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { makeStyles } from "@mui/styles";

const GameIframe = ({ url, userID, projectID }) => {
  //#region STYLES
  const useStyles = makeStyles(() => ({
    pageSetting: {},
  }));

  const classes = useStyles();
  //#endregion

  let gameUrl = `${url}?user_id=${userID}&project_id=${projectID}&version=3`;
  console.log(gameUrl);

  return (
    <div className={classes.pageSetting}>
      <Grid container spacing={3} direction={"column"}>
        <Grid item>
          <Button
            startIcon={<RefreshIcon />}
            variant="h6"
            style={{ backgroundColor: "rgb(221,107,32)", color: "white" }}
            onClick={() => {
              document.getElementById("iFrame").src += "";
            }}
          >
            Refresh
          </Button>
        </Grid>
        <Grid item>
          <iframe
            style={{
              width: "100%",
              height: "550px",
              margin: "none",
              overflow: "hidden",
            }}
            title="iframe"
            src={gameUrl}
            id="iFrame"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GameIframe;
