import {
  TextField,
  Input,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import Button from "@mui/material/Button";
import logo from "../../image/imi.png";
import { useState } from "react";
import { style } from "@mui/system";
import { makeStyles } from "@mui/styles";

const CreateProjectDialog = ({ open, onClose, data }) => {
  const useStyles = makeStyles((theme) => ({
    dialogButton: {
      backgroundColor: "rgb(221,107,22)",
      color: "white",
      // padding: "5px 10px 5px 10px",
      // "&:hover": {
      //   backgroundColor: "rgba(221,107,22)",
      //   color: "white",
      // },
    },
    images: {
      width: "150px",
      height: "150px",
    },
  }));

  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    templateID: 0,
  });

  const onTextFieldChange = (field, e) => {
    //console.log(e.target.value);
    //setFormData({ ...prevData, field: e.target.value });
    setFormData((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
    //console.log(formData);
  };

  const onActionBtnClick = (state) => {
    if (state) {
      if (formData.name !== "") {
        onClose(true, formData);
      } else {
      }
    } else {
      onClose(false);
    }
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Create a Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="projectName"
            label="Project Name"
            required
            fullWidth
            variant="outlined"
            helperText="name can not be empty"
            value={formData.name}
            onChange={(e) => onTextFieldChange("name", e)}
          />
          <TextField
            margin="dense"
            id="ProjectDescription"
            label="Project Description"
            fullWidth
            variant="outlined"
            value={formData.description}
            onChange={(e) => onTextFieldChange("description", e)}
          />
          <FormControl component="fieldset">
            <FormLabel required component="legend">
              Select a template
            </FormLabel>
            <RadioGroup
              row
              aria-label="templates"
              name="row-radio-buttons-group"
            >
              {data
                ? data.map((item) => (
                    <FormControlLabel
                      value={item.id}
                      control={
                        <img
                          src={item.thumbnail}
                          alt=""
                          //className={classes.images}
                          style={{
                            opacity: formData.templateID === item.id ? 1 : 0.5,
                          }}
                          onClick={() => {
                            //console.log("Clicked");
                            if (item.id !== formData.templateID) {
                              setFormData((prevState) => ({
                                ...prevState,
                                templateID: item.id,
                              }));
                              //console.log(formData);
                            }
                          }}
                        />
                      }
                      label=""
                      key={item.id}
                    />
                  ))
                : null}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              onActionBtnClick(true);
            }}
          >
            Create New Project
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              onActionBtnClick(false);
            }}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateProjectDialog;
