import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiData";

const customizeApi = createApi({
  reducerPath: "customizeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    updateBasicInfo: builder.mutation({
      query: ({ token, id, data }) => ({
        url: `/project/${id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: {
          name: data.name,
          description: data.description,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    updateGameThumbnail: builder.mutation({
      query: ({ token, id, image }) => ({
        url: `/file/upload/${id}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: image,
      }),
      transformResponse: (response) => response?.data,
    }),
    updateOtherSettings: builder.mutation({
      query: ({ token, projectID, data }) => ({
        url: `/project/detail/${projectID}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip, deflate, br",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      }),
      transformResponse: (response) => response?.data,
    }),
    updateGameConfigAsset: builder.mutation({
      query: ({ token, projectID, assetID, asset }) => ({
        url: `/file/upload/${projectID}/${assetID}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: asset,
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useUpdateBasicInfoMutation,
  useUpdateGameThumbnailMutation,
  useUpdateOtherSettingsMutation,
  useUpdateGameConfigAssetMutation,
} = customizeApi;
export default customizeApi;
