import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiData";

const homeApi = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getTemplateList: builder.mutation({
      query: () => ({
        url: "/templates",
        method: "GET",
        headers: {},
      }),
      transformResponse: (response) => response,
    }),
    getProjectList: builder.query({
      query: (userToken) => ({
        url: `/project`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response,
    }),
    createProject: builder.mutation({
      query: ({ userToken, data }) => ({
        url: `/project`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          name: data.name,
          description: data.description,
          template_id: data.templateID,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getProjectData: builder.mutation({
      query: ({ userToken, projectID }) => ({
        url: `/project/${projectID}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetTemplateListMutation,
  useGetProjectListQuery,
  useGetProjectDataMutation,
} = homeApi;
export default homeApi;
