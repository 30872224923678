import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import bgImage from "../../image/login-bg.jpg";
import logo from "../../image/imi.png";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "../../store/Store";
import { Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const Login = () => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    loginBg: {
      backgroundImage: "url(" + bgImage + ")",
      height: "100vh",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "relative",
      width: "100vw",
    },
    loginStyle: {
      textAlign: "center",
      backgroundColor: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      opacity: 0.9,
    },
  }));
  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(keycloak.authenticated);
  //   if (keycloak.authenticated) {
  //     console.log(keycloak.token);
  //     //dispatch(homeActions.change_IsLoggedIn(true));
  //     navigate("/", { replace: true });
  //   }
  // }, [keycloak]);

  //#endregion

  if (keycloak?.authenticated) {
    //console.log(keycloak.token);
    dispatch(homeActions.setUserToken(keycloak.token));
    return <Navigate to="/" replace />;
  }

  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.loginBg}>
        <Container className={classes.loginStyle} maxWidth="xs">
          <Grid
            justifyContent="center"
            alignItems="center"
            spacing={1}
            container
            direction={"column"}
          >
            <Grid item>
              <img
                src={logo}
                alt="imi logo"
                style={{ backgroundColor: "grey" }}
              />
            </Grid>
            <Grid item>
              <Typography
                style={{
                  color: "rgb(116,116,116)",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                IMI Game Maker V3
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  keycloak.login();
                }}
                variant="h6"
                style={{ backgroundColor: "rgb(221,107,32)", color: "white" }}
              >
                Login/Register
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

export default Login;
