import { Box, Input, TextField } from "@mui/material";
import React, { useRef } from "react";

const CustomFileUploader = ({ onChangeFn }) => {
  const handleFileInput = (e) => {
    // handle validations
    //onChangeFn(e.target.files[0]);
    onChangeFn(e.target.files[0]);
  };

  return (
    <Box className="file-uploader">
      <Input
        margin="none"
        id="Game Thumbnail"
        fullWidth
        required
        type="file"
        variant="filled"
        onChange={handleFileInput}
      />
    </Box>
  );
};

export default CustomFileUploader;
