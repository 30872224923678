import React from "react";
import { FormControl, FormLabel, Button, Grid, Box } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomInputField from "./Components/CustomInputField";
import CustomSlider from "./Components/CustomSlider";
import { useUpdateOtherSettingsMutation } from "../../../services/CustomizeApi";
import { useSelector } from "react-redux";



const OtherSettings = ({ initData, pID }) => {
  //#region Styles
  const useStyles = makeStyles(() => ({
    formStyle: {
      backgroundColor: "white",
      padding: "20px",
    },
    horizontalLine: {
      color: "rgb(238,181,144)",
      height: "1px",
      borderWidth: 0,
      backgroundColor: "rgb(238,181,144)",
    },
    resetButton: {
      color: "rgb(236,236,236)",
      backgroundColor: "rgb(247,67,54)",
      "&:hover": {
        backgroundColor: "rgb(247,67,54)",
        color: "rgb(236,236,236)",
      },
    },
    updateConfigButton: {
      color: "rgb(236,236,236)",
      backgroundColor: "rgb(221,107,32)",
      "&:hover": {
        backgroundColor: "rgb(221,107,32)",
        color: "rgb(236,236,236)",
      },
    },
    titleStyle:
    {
      color:'rgb(68,68,68)',
      fontSize:'14px',
      fontWeight:'bold'
    }
  }));
  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);
  //#endregion

  //#region DYNAMIC DATA

  const [dynamicData, setDynamicData] = useState(
    JSON.parse(JSON.stringify(initData.dynamicSettingList))
  );

  const onSliderChange = (newValue, index) => {
    let newState = [...dynamicData];
    newState[index].value = newValue;
    setDynamicData(newState);
  };

  const resetDynamicSettings = () => {
    let settings = [...JSON.parse(JSON.stringify(initData.dynamicSettingList))];
    settings.forEach((element) => {
      element.value = element.default_value;
    });
    setDynamicData(settings);
  };

  let dynamicSettingsContent = initData.dynamicSettingList.map(
    (item, index) => (
      <CustomSlider
        key={item.item_id}
        data={item}
        value={dynamicData[index].value}
        itemIndex={index}
        onChangeFn={onSliderChange}
      />
    )
  );
  //#endregion

  //#region TUTE DATA
  const [tuteData, setTuteData] = useState(
    JSON.parse(JSON.stringify(initData.tuteVideoList))
  );

  const onTuteInputFieldChange = (newValue, index) => {
    let newState = [...tuteData];
    newState[index].value = newValue;
    setTuteData(newState);
  };

  const resetTuteSettings = () => {
    let settings = [...JSON.parse(JSON.stringify(initData.tuteVideoList))];
    settings.forEach((element) => {
      element.value = element.default_value;
    });
    setTuteData(settings);
  };

  let tuteSettingsContent = initData.tuteVideoList.map((item, index) => (
    <CustomInputField
      key={item.item_id}
      displayName={item.display_name}
      videoID={tuteData[index].value}
      itemIndex={index}
      onChangeFn={onTuteInputFieldChange}
    />
  ));
  //#endregion

  //#region UPDATE SETTINGS

  //API
  const [
    updateOtherSettings,
    {
      isLoading: isLoadingUpdateOtherSettingsAPI,
      error: updateOtherSettingsAPIError,
      isSuccess: updateOtherSettingsAPISuccess,
      data: updateOtherSettingsAPIResponse,
    },
  ] = useUpdateOtherSettingsMutation();

  let newDynamicSettings = dynamicData.map((item) => {
    return {
      item_id: item.item_id,
      value: item.value,
    };
  });

  let newTuteSettings = tuteData.map((item) => {
    return {
      item_id: item.item_id,
      value: item.value,
    };
  });

  const updateConfigBtnClick = () => {
    updateOtherSettings({
      token: userToken,
      projectID: pID,
      data: [...newDynamicSettings, ...newTuteSettings],
    });
  };
  //#endregion

  return (
    <div>
        <form className={classes.formStyle}>
            <Grid container direction="column">
              <Grid item>
                <label className={classes.titleStyle}>
                  Dynamic Settings
                </label>
                <Box style={{paddingTop:'10px',marginLeft:'5px',marginRight:'10px'}}>
                  {dynamicSettingsContent}
                </Box>
                <hr className={classes.horizontalLine} />
              </Grid>
              <Grid item>
                <label className={classes.titleStyle}>
                  Tutorial Videos In Config
                </label>
                <Box style={{paddingTop:'10px',marginLeft:'5px',marginRight:'10px'}}>
                  {tuteSettingsContent}
                </Box>
                <hr className={classes.horizontalLine} />
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Button
                      style={{color:'white',backgroundColor:'rgb(244,67,54)'}}
                      onClick={() => {
                        resetDynamicSettings();
                        resetTuteSettings();
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{color:'white',backgroundColor:'rgb(221,107,32)',}}
                      onClick={updateConfigBtnClick}
                    >
                      Update Configuration
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        </form>
    </div>
  );
};

export default OtherSettings;
