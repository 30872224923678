import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import iImage from "../../image/i-phone.png";
import imi from "../../image/imi.png";
import AddIcon from "@mui/icons-material/Add";

const ProjectItem = ({ crateBtn, data, btnClick }) => {
  const useStyles = makeStyles((theme) => ({
    imgContainer: {
      height: "500px",
      position: "relative",
      display: "inline-block",
      aspectRatio: "8.9/19.5",
    },
    iphone: {
      height: "100%",
      position: "absolute",
      marginLeft: "0px",
    },
    innerContainer: {
      backgroundColor: "rgb(116,114,131)",
      height: "90%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
     
    },
    createButton: {
      color: "white",
      textAlign: "center",
      padding: "0px",
    },
  }));

  const classes = useStyles();

  let itemContent = null;

  if (crateBtn) {
    itemContent = (
      <div className={classes.createButton}>
        <Grid container justifyContent={"center"} textAlign={"center"} spacing={0} direction={"column"} >
          <Grid item>
            <AddIcon
            style={{
              color: "white",
              fontSize: "76px",
              padding:'0px'
            }}
            />
          </Grid>
          <Grid item>
            Create New project
          </Grid>
        </Grid>
      </div>
    );
  } else {
    itemContent = (
      <Fragment>
        <div style={{position:'relative',textAlign:'center',color:'white'}}>
            <img style={{minHeight:'200px',minWidth:'150px'}} src={data.image} alt="iphone" /> 
            <div style={{position:'absolute',top:'-82px',left:'-22px'}}>
              <Grid container direction={"column"}>
                <Grid item>
                  <Typography style={{color:'white',textAlign:'start',fontSize:'12px',fontWeight:'bold'}}>{data.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{color:'white',textAlign:'start',fontSize:'10px'}}>{data.description}</Typography>
                </Grid>
              </Grid>
            </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Grid item>
      <Button className={classes.imgContainer} onClick={btnClick}>
        <img src={iImage} alt="iphone" className={classes.iphone} />
        <div className={classes.innerContainer}> {itemContent}</div>
      </Button>
    </Grid>
  );
};

export default ProjectItem;
