import { Button, Typography, Grid } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateProjectMutation,
  useGetProjectDataMutation,
  useGetProjectListQuery,
  useGetTemplateListMutation,
} from "../../services/HomeApi";
import { homeActions } from "../../store/Store";
import bgImage from "../../image/page-bg.jpg";
import miniImg from "../../image/bg.png";
import iImage from "../../image/i-phone.png";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import ProjectItem from "./ProjectItem";
import { useNavigate } from "react-router-dom";
import CreateProjectDialog from "./CreateProjectDialog";
import LoadingPanel from "../../utils/LoadingPanel";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { minHeight } from "@mui/system";
import { useKeycloak } from "@react-keycloak/web";

const Home = () => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    backgroundImg: {
      backgroundImage: "url(" + bgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "relative",
      minHeight: "100vh",
    },
    miniImageStyle: {
      bottom: "0",
      right: "0",
      height: "350px",
      width: "290px",
      overflow: "hidden",
      position: "absolute",
    },
    phoneSetting: {
      padding: "40px",
      marginLeft: "20px",
      marginRight: "20px",
    },
  }));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const activeAPIIndex = useRef(0);

  //PROJECT LIST API
  const {
    data: projectListAPIResponse,
    error: projectListAPIError,
    isLoading: isLoadingProjectListAPI,
  } = useGetProjectListQuery(userToken);

  //TEMPLATE LIST API
  const [
    getTemplateList,
    {
      data: templateListAPIResponse,
      error: templateListAPIError,
      isLoading: isLoadingTemplateListAPI,
    },
  ] = useGetTemplateListMutation();

  //CREATE PROJECT API
  const [
    createProject,
    {
      data: createProjectAPIResponse,
      error: createProjectAPIError,
      isLoading: isLoadingCreateProjectAPI,
    },
  ] = useCreateProjectMutation();

  //GET PROJECT DATA API
  const [
    getProjectData,
    {
      data: getProjectDataAPIResponse,
      error: getProjectDataAPIError,
      isLoading: isLoadingGetProjectDataAPI,
    },
  ] = useGetProjectDataMutation();

  //NAVIGATE TO CUSTOMIZE PAGE ONCE WE RECEIVE PROJECT DATA
  useEffect(() => {
    if (activeAPIIndex.current === 4) {
      navigate("/customize", { state: { data: getProjectDataAPIResponse } });
      activeAPIIndex.current = 0;
    }
  }, [getProjectDataAPIResponse]);
  //#endregion

  //#region BTN CLICKS
  const createProjectBtnClick = () => {
    activeAPIIndex.current = 1;
    getTemplateList();
    //setOpenCreateProjectDialog(true);
  };

  const openProjectBtnClick = (id) => {
    activeAPIIndex.current = 3;
    getProjectData({
      userToken: userToken,
      projectID: id,
    });
  };
  //#endregion

  //#region PROJECT LIST API STATES
  let projectListAPIContent = null;

  if (isLoadingProjectListAPI) {
    projectListAPIContent = <LoadingPanel />;
  } else if (projectListAPIError) {
    console.log(projectListAPIError);

    let errorMsg = "Something Went Wrong";
    let callBack = null;

    if (projectListAPIError.status === 401) {
      errorMsg = "Session Expired";
      callBack = () => {
        //NAVIGATE TO LOGIN PAGE
        keycloak.logout();
        //navigate("/login");
      };
    } else {
      callBack = () => window.location.reload();
    }

    projectListAPIContent = (
      <FeedbackDialog
        open={true}
        onClose={callBack}
        data={{
          title: "Oops!",
          msg: errorMsg,
        }}
      />
    );
    //console.log(projectListAPIError);
  } else if (projectListAPIResponse) {
    projectListAPIContent = projectListAPIResponse.map((item) => (
      <ProjectItem
        crateBtn={false}
        key={item.project_id}
        data={{
          name: item.name,
          description: item.description,
          image: item.cover_image,
        }}
        btnClick={() => {
          openProjectBtnClick(item.project_id);
        }}
      />
    ));
  } else {
    projectListAPIContent = null;
  }
  //#endregion

  //#region CREATE PROJECT DIALOG
  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState({
    state: false,
    data: null,
  });

  const onCreateProjectDialogActionBtnClick = (state, data) => {
    if (state) {
      createProject({ userToken: userToken, data: data });
    } else {
    }
    setOpenCreateProjectDialog({ state: false, data: null });
  };

  let createProjectDialog = (
    <CreateProjectDialog
      open={openCreateProjectDialog.state}
      onClose={onCreateProjectDialogActionBtnClick}
      data={openCreateProjectDialog.data}
    />
  );
  //#endregion

  //#region TEMPLATE LIST API STATES

  let templateListApiContent;

  if (activeAPIIndex.current === 1) {
    if (isLoadingTemplateListAPI) {
      templateListApiContent = <Typography>Loading</Typography>;
    } else if (templateListAPIError) {
      activeAPIIndex.current = 0;
      templateListApiContent = (
        <Typography>Oh no, there was an error</Typography>
      );
      //console.log(projectListAPIError);
    } else if (templateListAPIResponse) {
      templateListApiContent = null;
      activeAPIIndex.current = 0;
      console.log(templateListAPIResponse);
      setOpenCreateProjectDialog({
        state: true,
        data: templateListAPIResponse,
      });
    } else {
      templateListApiContent = null;
    }
  } else {
    templateListApiContent = null;
  }

  //#endregion

  //#region CREATE PROJECT API STATES

  let creteProjectApiContent;

  if (activeAPIIndex.current === 2) {
    if (isLoadingCreateProjectAPI) {
      creteProjectApiContent = <Typography>Loading</Typography>;
    } else if (createProjectAPIError) {
      activeAPIIndex.current = 0;
      creteProjectApiContent = (
        <Typography>Oh no, there was an error</Typography>
      );
      //console.log(projectListAPIError);
    } else if (createProjectAPIResponse) {
      creteProjectApiContent = null;
      activeAPIIndex.current = 0;
    } else {
      creteProjectApiContent = null;
    }
  } else {
    creteProjectApiContent = null;
  }

  //#endregion

  //#region GET PROJECT DATA API STATES

  let getProjectDataApiContent = null;

  if (activeAPIIndex.current === 3) {
    if (isLoadingGetProjectDataAPI) {
      getProjectDataApiContent = <Typography>Loading</Typography>;
    } else if (getProjectDataAPIError) {
      activeAPIIndex.current = 0;
      getProjectDataApiContent = (
        <Typography>Oh no, there was an error</Typography>
      );
      //console.log(projectListAPIError);
    } else if (getProjectDataAPIResponse) {
      getProjectDataApiContent = null;
      activeAPIIndex.current = 4;
    } else {
      getProjectDataApiContent = null;
    }
  }

  //#endregion

  return (
    <div className={classes.backgroundImg}>
      <img src={miniImg} alt="miniImage" className={classes.miniImageStyle} />
      <div className={classes.phoneSetting}>
        <Grid justifyContent="center" alignItems="center" container spacing={2}>
          <ProjectItem crateBtn={true} btnClick={createProjectBtnClick} />
          {projectListAPIContent}
        </Grid>
      </div>
      {templateListApiContent}
      {createProjectDialog}
      {creteProjectApiContent}
      {getProjectDataApiContent}
    </div>
  );
};

export default Home;
