import { Grid,Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';


const Input = styled(MuiInput)`
  background-Color:rgb(247,221,203);
`;

const CustomInputField = ({ displayName, videoID, itemIndex, onChangeFn }) => {
  const onChange = (event) => {
    onChangeFn(event.target.value, itemIndex);
  };
  return (
    <Grid container sx={{ mb: 1 }}  spacing={3} direction="row" justifyContent={"space-between"} >
      <Grid item xs={5}>
        <Typography style={{fontSize:'13px'}}>{displayName}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Input
          style={{fontSize:'12px',padding:'0px',}}
          margin="none"
          value={videoID}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default CustomInputField;
