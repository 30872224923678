import { configureStore, createSlice } from "@reduxjs/toolkit";
import customizeApi from "../services/CustomizeApi";
import homeApi from "../services/HomeApi";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    count: 100,
    userToken:
      "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3SXkxUFQyc09HY01jdmlrQVc5dkVMUWQySnJJM1VwdFNUeVh6dEI1RWJBIn0.eyJleHAiOjE2NDQzNzg0MDEsImlhdCI6MTY0NDM2MDQwMSwiYXV0aF90aW1lIjoxNjQ0MzYwMzk5LCJqdGkiOiI0M2Y5NDBjYy05OTMxLTRiYzUtYWI4NS05YjQ0ZjM3ZTFiMTgiLCJpc3MiOiJodHRwczovL2FwaS5zdGFnaW5nLmltaWdhbWVzLmlvL2F1dGgvcmVhbG1zL0lNSS1HYW1lLU1ha2VyIiwiYXVkIjoiaW1pLWdhbWUtbWFrZXItd2ViLWNsaWVudCIsInN1YiI6IjYyMmEzMjM4LTlmYjItNDNkOC1iZmM0LWM4NjY2NzU5YTViOSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImltaS1nYW1lLW1ha2VyLXdlYi1jbGllbnQiLCJzZXNzaW9uX3N0YXRlIjoiNDljMjIwMzEtYzlhOS00YTc5LWE2NDMtMmRiMmFjYWZmNjUzIiwiYWNyIjoiMSIsInNjb3BlIjoiaW1pLWdhbWUtbWFrZXItc2NvcGUiLCJ1c2VyX2lkIjoiNjIyYTMyMzgtOWZiMi00M2Q4LWJmYzQtYzg2NjY3NTlhNWI5IiwidXNlcl9uYW1lIjoibHluY2hhbWlrYUBnbWFpbC5jb20iLCJhdXRob3JpdGllcyI6WyJkZWZhdWx0LXJvbGVzLWltaS1nYW1lLW1ha2VyIiwiaW1pX2dhbWVfbWFrZXJfZGV2ZWxvcGVyIl19.BMAI6tuwYHbYg18d8vklWn8y2Q9u5SGrMqsPd6C_Miiy0HgStHcYg3Zeimne5B65sXrDP87SDZ_3PuT_h6c4UqBslDVzjgCKE2oVEyMyleyTlkB7dBEHqoS2rwNmVqj_TSVaJySEbdA-1mE1Lp_eNjVYcy_gGhzAKHMyKOf-KRdzCc3Rpz0G1zWjIw3TyhIYRposUw_2xrOHRC6iG87JFJAhBSC10CX2uDT_qsX-btJhXCdI-7J6jRAoQTeVUz-rb3V4FmdXDvP0wB2643VhjE2f5_JhG-2HH118tWdeFa2H86G0QjRsoXAGmAWV3eWIpvZTbNbpnmFzYDW01rnHCg",
  },
  reducers: {
    increase: (state) => {
      state.count++;
    },
    decrease: (state) => {
      state.count--;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [customizeApi.reducerPath]: customizeApi.reducer,
  },
  //CACHING WILL BE HANDLED BY THE MIDDLEWARE
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(homeApi.middleware)
      .concat(customizeApi.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
