import React, { useState } from "react";
import bgImage from "../../../image/page-bg.jpg";
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import RefreshIcon from "@mui/icons-material/Refresh";
import BasicDetails from "./BasicDetails";
import GameConfiguration from "./GameConfiguration";
import OtherSettings from "./OtherSettings";

const configBtns = {
  BD: "Basic Details Btn",
  GC: "Game Configuration Btn",
  OS: "Other Settings Btn",
};

const GameCustomize = ({ data }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    backgroundImg: {
      backgroundImage: "url(" + bgImage + ")",
      height: "100%",
      width: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "repeat",
    },
    pageSetting: {

    },
    btnStyle: {
      color: "rgb(221,107,32)",
      backgroundColor: "rgb(236,236,236)",
      "&:hover": {
        backgroundColor: "rgb(236,236,236)",
        color: "rgb(221,107,32)",
      },
    },
    refreshBtnStyle: {
      color: "rgb(236,236,236)",
      backgroundColor: "rgb(221,107,32)",
      "&:hover": {
        backgroundColor: "rgb(221,107,32)",
        color: "rgb(236,236,236)",
      },
    },
  }));

  const btnNonSelected = {
    color: "rgb(221,107,32)",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(221,107,32)",
    },
  };
  const btnSelected = {
    color: "rgb(236,236,236)",
    backgroundColor: "rgb(221,107,32)",
    "&:hover": {
      backgroundColor: "rgb(221,107,32)",
      color: "rgb(236,236,236)",
    },
  };

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  const [activeBtn, setActiveBtn] = useState(configBtns.BD);

  const changeActiveBtn = (btn) => {
    setActiveBtn(btn);
  };
  //#endregion

  let configContent = null;

  if (activeBtn === configBtns.BD) {
    configContent = (
      <BasicDetails
        initBasicData={{ name: data.name, description: data.description }}
        pID={data.project_id}
      />
    );
  } else if (activeBtn === configBtns.GC) {
    configContent = (
      <GameConfiguration
        initData={{
          loadingScreenList:
            data.detail_model.dynamic_config.asset_settings[0].category_item,
          homeScreenList:
            data.detail_model.dynamic_config.asset_settings[1].category_item,
          gamePlayList:
            data.detail_model.dynamic_config.asset_settings[2].category_item,
          gameOverScreenList:
            data.detail_model.dynamic_config.asset_settings[3].category_item,
          audioList:
            data.detail_model.dynamic_config.audio_settings[0].category_item,
        }}
        pID={data.project_id}
      />
    );
  } else {
    configContent = (
      <OtherSettings
        initData={{
          dynamicSettingList:
            data.detail_model.dynamic_config.range_settings[0].category_item,
          tuteVideoList:
            data.detail_model.dynamic_config.text_settings[0].category_item,
        }}
        pID={data.project_id}
      />
    );
  }

  return (
      <div className={classes.pageSetting}>
        <Grid direction={"column"}  container spacing={3}>
          <Grid item >
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item >
                <Button 
                  startIcon={<EditIcon />}
                  className={classes.btnStyle}
                  variant="h6"
                  onClick={() => {
                    changeActiveBtn(configBtns.BD);
                  }}
                  style={activeBtn === configBtns.BD ? btnSelected : btnNonSelected}
                >
                  Basic Details
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<SettingsIcon />}
                  className={classes.btnStyle}
                  variant="h6"
                  onClick={() => {
                    changeActiveBtn(configBtns.GC);
                  }}
                  style={activeBtn === configBtns.GC ? btnSelected : btnNonSelected}
                >
                  Game Configuration
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<SettingsEthernetIcon />}
                  className={classes.btnStyle}
                  variant="h6"
                  onClick={() => {
                    changeActiveBtn(configBtns.OS);
                  }}
                  style={activeBtn === configBtns.OS ? btnSelected : btnNonSelected}
                >
                  Other Settings
                </Button>
              </Grid>
              </Grid>
          </Grid>
          <Grid item>
            {configContent}
          </Grid>
        </Grid>
      </div>
  );
};

export default GameCustomize;
