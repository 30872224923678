import React, { useRef, useState } from "react";
import {
    Button,
  MenuItem,
  Select,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomFileUploader from "./Components/CustomFileUploader";
import { Fragment } from "react";
import { useUpdateGameConfigAssetMutation } from "../../../services/CustomizeApi";
import { useSelector } from "react-redux";



const GameConfiguration = ({ initData, pID }) => {
  console.log(initData.loadingScreenList);
  //#region styles
  const useStyles = makeStyles(() => ({
    formStyle: {
      backgroundColor: "white",
      padding: "20px",
    },
    horizontalLine: {
      color: "rgb(238,181,144)",
      height: "1px",
      borderWidth: 0,
      backgroundColor: "rgb(238,181,144)",
    },
  }));
  const classes = useStyles();
  //#endregion

  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  //#endregion

  //#region LOADING SCREEN

  const [loadingScreenDataList, setLoadingScreenDataList] = useState(
    initData.loadingScreenList
  );

  const [loadingScreenSelectItem, setLoadingScreenSelectItem] = useState(0);

  const loadingScreenRecommendText = useRef("");

  const ChangeLoadingScreenSelectionValue = (event) => {
    let index = event.target.value;

    setLoadingScreenSelectItem(index);

    if (index === 0) {
      loadingScreenRecommendText.current = "";
    } else {
      loadingScreenRecommendText.current = `Recommended Size: ${
        loadingScreenDataList[index - 1].height
      }*${loadingScreenDataList[index - 1].width}`;
    }
  };

  const onLoadingScreenImageChange = (newImage) => {
    let newState = [...loadingScreenDataList];
    newState[loadingScreenSelectItem - 1].file = newImage;
    setLoadingScreenDataList(newState);
  };

  // const resetDynamicSettings = () => {
  //   let settings = [...JSON.parse(JSON.stringify(initData.dynamicSettingList))];
  //   settings.forEach((element) => {
  //     element.value = element.default_value;
  //   });
  //   setDynamicData(settings);
  // };
  const uploadBtnClick_LoadingScreen = () => {
    uploadBtnClick(
      loadingScreenDataList[loadingScreenSelectItem - 1].image_id,
      loadingScreenDataList[loadingScreenSelectItem - 1].file
    );
  };

  let loadingScreenContent = (
    <Fragment>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography style={{fontWeight:'bold'}} >
            Loading Screen
          </Typography>
        </Grid>
        <Grid item >
          <Grid container spacing={2} direction={"row"} justifyContent="space-between">
            <Grid item>
              <Select
                size="small"
                id="loadingScreen"
                value={loadingScreenSelectItem}
                onChange={ChangeLoadingScreenSelectionValue}
              >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {initData.loadingScreenList.map((item, index) => (
                <MenuItem key={item.image_id} value={index + 1}>
                  {item.display_name}
                </MenuItem>
              ))}
              </Select>
            </Grid>
            <Grid item>
              <Typography style={{fontSize:'12px'}}>{loadingScreenRecommendText.current}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container textAlign="center" direction={"row"} justifyContent="space-between" >
              <Grid item>
                {loadingScreenSelectItem !== 0 ?
                  <CustomFileUploader
                    onChangeFn={onLoadingScreenImageChange}/>
                  :null
                }
              </Grid>
              <Grid item>
                <Button
                  style={{
                    backgroundColor: "rgb(221,107,32)",
                    color: "rgb(236,236,236)",
                  }}
                  disabled={loadingScreenSelectItem === 0 ? true : false}
                  onClick={() => {
                    uploadBtnClick_LoadingScreen();
                  }}
                >
                  Upload
                </Button>
              </Grid>
          </Grid>
        </Grid>
      </Grid> 
    </Fragment>
  );

  //#endregion

  //#region HOME SCREEN

  const [homeScreenDataList, setHomeScreenDataList] = useState(
    initData.homeScreenList
  );

  const [homeScreenSelectItem, setHomeScreenSelectItem] = useState(0);

  const homeScreenRecommendText = useRef("");

  const ChangeHomeScreenSelectionValue = (event) => {
    let index = event.target.value;

    setHomeScreenSelectItem(index);

    if (index === 0) {
      homeScreenRecommendText.current = "";
    } else {
      homeScreenRecommendText.current = `Recommended Size: ${
        homeScreenDataList[index - 1].height
      }*${homeScreenDataList[index - 1].width}`;
    }
  };

  const onHomeScreenImageChange = (newImage) => {
    let newState = [...homeScreenDataList];
    newState[homeScreenSelectItem - 1].file = newImage;
    setHomeScreenDataList(newState);
  };

  // const resetDynamicSettings = () => {
  //   let settings = [...JSON.parse(JSON.stringify(initData.dynamicSettingList))];
  //   settings.forEach((element) => {
  //     element.value = element.default_value;
  //   });
  //   setDynamicData(settings);
  // };

  const uploadBtnClick_HomeScreen = () => {
    uploadBtnClick(
      homeScreenDataList[homeScreenSelectItem - 1].image_id,
      homeScreenDataList[homeScreenSelectItem - 1].file
    );
  };

  let homeScreenContent = (
    <Fragment>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography style={{fontWeight:'bold'}} >
            Home Screen
          </Typography>
        </Grid>
        <Grid item >
          <Grid container spacing={2} direction={"row"} justifyContent="space-between">
            <Grid item>
              <Select
                size="small"
                id="homeScreen"
                value={homeScreenSelectItem}
                onChange={ChangeHomeScreenSelectionValue}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                {initData.homeScreenList.map((item, index) => (
                  <MenuItem key={item.image_id} value={index + 1}>
                    {item.display_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Typography style={{fontSize:'12px'}}>{homeScreenRecommendText.current}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
            <Grid container textAlign="center" direction={"row"} justifyContent="space-between" >
              <Grid item>
                {homeScreenSelectItem !== 0 ?
                  <CustomFileUploader
                    onChangeFn={onHomeScreenImageChange}/>
                  :null
                }
              </Grid>
              <Grid item>
                <Button
                  style={{
                    backgroundColor: "rgb(221,107,32)",
                    color: "rgb(236,236,236)",
                  }}
                  disabled={homeScreenSelectItem === 0 ? true : false}
                  onClick={uploadBtnClick_HomeScreen}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
        </Grid>
      </Grid> 
    </Fragment>
  );

  //#endregion

  //#region GAME PLAY

  const [gamePlayDataList, setGamePlayDataList] = useState(
    initData.gamePlayList
  );

  const [gamePlaySelectItem, setGamePlaySelectItem] = useState(0);

  const gamePlayRecommendText = useRef("");

  const ChangeGamePlaySelectionValue = (event) => {
    let index = event.target.value;

    setGamePlaySelectItem(index);

    if (index === 0) {
      gamePlayRecommendText.current = "";
    } else {
      gamePlayRecommendText.current = `Recommended Size: ${
        gamePlayDataList[index - 1].height
      }*${gamePlayDataList[index - 1].width}`;
    }
  };

  const onGamePlayImageChange = (newImage) => {
    let newState = [...gamePlayDataList];
    newState[gamePlaySelectItem - 1].file = newImage;
    setGamePlayDataList(newState);
  };

  const uploadBtnClick_GamePlay = () => {
    uploadBtnClick(
      gamePlayDataList[gamePlaySelectItem - 1].image_id,
      gamePlayDataList[gamePlaySelectItem - 1].file
    );
  };

  let gamePlayContent = (
    <Fragment>
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography style={{fontWeight:'bold'}} >
          Game Play
        </Typography>
      </Grid>
      <Grid item >
        <Grid container spacing={2} direction={"row"} justifyContent="space-between">
          <Grid item>
            <Select
              size="small"
              id="gamePlay"
              value={gamePlaySelectItem}
              onChange={ChangeGamePlaySelectionValue}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {initData.gamePlayList.map((item, index) => (
                <MenuItem key={item.image_id} value={index + 1}>
                  {item.display_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Typography style={{fontSize:'12px'}}>{gamePlayRecommendText.current}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
          <Grid container textAlign="center" direction={"row"} justifyContent="space-between" >
            <Grid item>
              {gamePlaySelectItem !== 0 ?
                <CustomFileUploader
                  onChangeFn={onGamePlayImageChange}/>
                :null
              }
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: "rgb(221,107,32)",
                  color: "rgb(236,236,236)",
                }}
                disabled={gamePlaySelectItem === 0 ? true : false}
                onClick={() => {
                  uploadBtnClick_GamePlay();
                }}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
      </Grid>
    </Grid> 
  </Fragment>
  );

  //#endregion

  //#region GAME OVER SCREEN

  const [gameOverScreenDataList, setGameOverScreenDataList] = useState(
    initData.gameOverScreenList
  );

  const [gameOverScreenSelectItem, setGameOverScreenSelectItem] = useState(0);

  const gameOverScreenRecommendText = useRef("");

  const ChangeGameOverScreenSelectionValue = (event) => {
    let index = event.target.value;

    setGameOverScreenSelectItem(index);

    if (index === 0) {
      gameOverScreenRecommendText.current = "";
    } else {
      gameOverScreenRecommendText.current = `Recommended Size: ${
        gameOverScreenDataList[index - 1].height
      }*${gameOverScreenDataList[index - 1].width}`;
    }
  };

  const onGameOverScreenImageChange = (newImage) => {
    let newState = [...gameOverScreenDataList];
    newState[gameOverScreenSelectItem - 1].file = newImage;
    setGameOverScreenDataList(newState);
  };

  const uploadBtnClick_GameOverScreen = () => {
    uploadBtnClick(
      gameOverScreenDataList[gameOverScreenSelectItem - 1].image_id,
      gameOverScreenDataList[gameOverScreenSelectItem - 1].file
    );
  };

  let gameOverScreenContent = (
  <Fragment>
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography style={{fontWeight:'bold'}} >
          Game Over Screen
        </Typography>
      </Grid>
      <Grid item >
        <Grid container spacing={2} direction={"row"} justifyContent="space-between">
          <Grid item>
            <Select
              size="small"
              id="gamePlay"
              value={gameOverScreenSelectItem}
              onChange={ChangeGameOverScreenSelectionValue}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {initData.gameOverScreenList.map((item, index) => (
                <MenuItem key={item.image_id} value={index + 1}>
                  {item.display_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <Typography style={{fontSize:'12px'}}>{gameOverScreenRecommendText.current}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box>
          <Grid container textAlign="center" direction={"row"} justifyContent="space-between" >
            <Grid item>
              {gameOverScreenSelectItem !== 0 ?
                <CustomFileUploader
                 onChangeFn={onGameOverScreenImageChange}/>
                :null
              }
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: "rgb(221,107,32)",
                  color: "rgb(236,236,236)",
                }}
                disabled={gameOverScreenSelectItem === 0 ? true : false}
                onClick={() => {
                  uploadBtnClick_GameOverScreen();
                }}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid> 
  </Fragment>
  );

  //#endregion

  //#region AUDIO

  const [audioDataList, setAudioDataList] = useState(initData.audioList);

  const [audioSelectItem, setAudioSelectItem] = useState(0);

  //const audioRecommendText = useRef("");

  const ChangeAudioSelectionValue = (event) => {
    let index = event.target.value;

    setAudioSelectItem(index);

    // if (index === 0) {
    //   audioRecommendText.current = "";
    // } else {
    //   audioRecommendText.current = `Recommended Size: ${
    //     audioDataList[index - 1].height
    //   }*${audioDataList[index - 1].width}`;
    // }
  };

  const onAudioClipChange = (newAudio) => {
    let newState = [...audioDataList];
    newState[audioSelectItem - 1].file = newAudio;
    setAudioDataList(newState);
  };

  const uploadBtnClick_Audio = () => {
    uploadBtnClick(
      audioDataList[audioSelectItem - 1].image_id,
      audioDataList[audioSelectItem - 1].file
    );
  };

  let audioContent = (
  <Fragment>
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography style={{fontWeight:'bold'}} >
          Audio Settings
        </Typography>
      </Grid>
      <Grid item >
        <Grid container spacing={2} direction={"row"} justifyContent="space-between">
          <Grid item>
            <Select
              size="small"
              id="audio"
              value={audioSelectItem}
              onChange={ChangeAudioSelectionValue}
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>
              {initData.audioList.map((item, index) => (
                <MenuItem key={item.image_id} value={index + 1}>
                  {item.display_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item>
            {/* <Typography style={{fontSize:'12px'}}>{gamePlayRecommendText.current}</Typography> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
          <Grid container textAlign="center" direction={"row"} justifyContent="space-between" >
            <Grid item>
              {audioSelectItem !== 0 ?
                <CustomFileUploader
                onChangeFn={onAudioClipChange}/>
                :null
              }
            </Grid>
            <Grid item>
              <Button
                style={{
                  backgroundColor: "rgb(221,107,32)",
                  color: "rgb(236,236,236)",
                }}
                disabled={audioSelectItem === 0 ? true : false}
                onClick={() => {
                  uploadBtnClick_Audio();
                }}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
      </Grid>
    </Grid> 
  </Fragment>
  );

  //#endregion

  //#region UPDATE ASSET API
  const [
    updateGameConfigAsset,
    {
      isLoading: isLoadingUpdateGameConfigAssetAPI,
      error: updateGameConfigAssetAPIError,
      isSuccess: updateGameConfigAssetAPISuccess,
      data: updateGameConfigAssetAPIResponse,
    },
  ] = useUpdateGameConfigAssetMutation();

  const uploadBtnClick = (aID, file) => {
    let formData = new FormData();
    formData.append("file", file);

    updateGameConfigAsset({
      token: userToken,
      projectID: pID,
      assetID: aID,
      asset: formData,
    });
  };
  //#endregion

  return (
    <div>
      <form className={classes.formStyle}>
      <Grid container direction="column">
        <Grid item>
          {loadingScreenContent}
          <hr className={classes.horizontalLine} />
        </Grid>
        <Grid item>
          {homeScreenContent}
          <hr className={classes.horizontalLine} />
        </Grid>
        <Grid item>
          {gamePlayContent}
          <hr className={classes.horizontalLine} />
        </Grid>
        <Grid item>
          {gameOverScreenContent}
          <hr className={classes.horizontalLine} />
        </Grid>
        <Grid item>
          {audioContent}
          <hr className={classes.horizontalLine} />
        </Grid>
      </Grid>
      </form>
    </div>
  );
};

export default GameConfiguration;
