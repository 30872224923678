import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import CustomAppBar from "./components/AppBar/CustomAppBar";
import CustomizePage from "./components/Customize";
import Home from "./components/Home/Home";

import Login from "./components/Login/login";
import PageNotFound from "./utils/PageNotFound";
import bgImage from "./image/page-bg.jpg";
function App() {
  let location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location]);

  const { initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      {show && <CustomAppBar />}
      <Routes>
        <Route path="/" element={<Navigate to={"/home"} />} />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/customize"
          element={
            <RequireAuth>
              <CustomizePage />
            </RequireAuth>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Fragment>
  );
}

export default App;

//WHEN CALLING THE DIRECT LINK
function RequireAuth({ children }) {
  //let auth = useAuth();
  //let location = useLocation();
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
}
