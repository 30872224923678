import React, { useRef, useState } from "react";
import { TextField,Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  useUpdateBasicInfoMutation,
  useUpdateGameThumbnailMutation,
} from "../../../services/CustomizeApi";
import { useSelector } from "react-redux";
import FeedbackDialog from "../../../utils/FeedbackDialog";
import LoadingPanel from "../../../utils/LoadingPanel";
import CustomFileUploader from "./Components/CustomFileUploader";



const BasicDetails = ({ initBasicData, pID }) => {
    //#region styles
    const useStyles = makeStyles(() => ({
      formStyle: {
        backgroundColor: "white",
        padding: "20px",
      },
      btnStyle: {
        color: "white",
        backgroundColor: "rgb(221,107,32)",
        "&:hover": {
          backgroundColor: "rgb(221,107,32)",
          color: "white",
        },
      },
    }));
    const classes = useStyles();
    //#endregion

  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);

  const [basicData, setBasicData] = useState(initBasicData);
  const gameThumbnail = useRef(null);

  //const activeAPIIndex = useRef(0);
  const [activeAPIIndex, setActiveAPIIndex] = useState(0);

  //UPDATE BASIC INFO API
  const [
    updateBasicInfo,
    {
      error: updateBasicInfoAPIError,
      isLoading: isLoadingUpdateBasicInfoAPI,
      isSuccess: updateBasicInfoAPISuccess,
    },
  ] = useUpdateBasicInfoMutation();

  const [
    updateGameThumbnail,
    {
      isLoading: isLoadingUpdateGameThumbnailAPI,
      isSuccess: updateGameThumbnailAPISuccess,
      error: updateGameThumbnailAPIError,
    },
  ] = useUpdateGameThumbnailMutation();
  //#endregion

  //#region FORM FIELDS EVENTS
  const onNameChange = (event) => {
    setBasicData({
      ...basicData,
      name: event.target.value,
    });
  };

  const onDescriptionChange = (event) => {
    setBasicData({
      ...basicData,
      description: event.target.value,
    });
  };

  const onGameThumbnailChange = (file) => {
    gameThumbnail.current = file;
  };

  const updateBtnClick = () => {
    updateBasicInfo({
      token: userToken,
      id: pID,
      data: basicData,
    });

    setActiveAPIIndex(1);
  };
  //#endregion

  //#region DATA UPLOAD API STATES

  let commonApiContent;

  if (activeAPIIndex === 1) {
    if (isLoadingUpdateBasicInfoAPI) {
      commonApiContent = <LoadingPanel />;
    } else if (updateBasicInfoAPISuccess) {
      commonApiContent = null;

      let formData = new FormData();
      formData.append("file", gameThumbnail.current);

      updateGameThumbnail({
        token: userToken,
        id: pID,
        image: formData,
      });

      setActiveAPIIndex(2);
    } else {
      console.log(updateBasicInfoAPIError);
      commonApiContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setActiveAPIIndex(0);
            commonApiContent = null;
          }}
          data={{
            title: "Oops!",
            msg: "Data Update Failed",
          }}
        />
      );
    }
  } else if (activeAPIIndex === 2) {
    if (isLoadingUpdateGameThumbnailAPI) {
      commonApiContent = <LoadingPanel />;
    } else if (updateGameThumbnailAPISuccess) {
      commonApiContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setActiveAPIIndex(0);
            commonApiContent = null;
          }}
          data={{
            title: "Great!",
            msg: "Data Successfully Uploaded",
          }}
        />
      );
    } else {
      console.log(updateGameThumbnailAPIError);
      commonApiContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setActiveAPIIndex(0);
            commonApiContent = null;
          }}
          data={{
            title: "Oops!",
            msg: "Image Upload Failed !",
          }}
        />
      );
    }
  }

  //#endregion

  return (
    <div>
      <form className={classes.formStyle}>
        <Grid  container spacing={3} direction="column">
          <Grid item>
            <label>
              Project Name
              <span style={{color:'rgb(229,62,62)'}}>{" "}*</span>
            </label>
            <br/>
            <TextField
                autoFocus
                error
                margin="dense"
                id="projectName"
                required
                fullWidth
                variant="outlined"
                value={basicData.name}
                onChange={onNameChange}
            />
          </Grid>
          <Grid item>
            <label>
              ProjectDescription
            </label>
            <br/>
            <TextField
              margin="dense"
              id="ProjectDescription"
              fullWidth
              required
              variant="outlined"
              value={basicData.description}
              onChange={onDescriptionChange}
            />
          </Grid>
          <Grid item>
            <label >
              Game Thumbnail
            </label>
            <br/>
            <CustomFileUploader onChangeFn={onGameThumbnailChange} />
          </Grid>
          <Grid item>
            <Button style={{ backgroundColor: "rgb(221,107,32)", color: "white" }} className={classes.btnStyle} onClick={updateBtnClick}>
              Update Details
            </Button>
          </Grid>
        </Grid>
      </form>
      {commonApiContent}
    </div>
  );
};

export default BasicDetails;
