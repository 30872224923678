import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useGetProjectDataQuery } from "../../services/CustomizeApi";
import GameCustomize from "./Game Customize/GameCustomize";
import GameIframe from "./GameIframe";
import bgImage from "../../image/page-bg.jpg";
import { makeStyles } from "@mui/styles";

const CustomizePage = () => {
  const { state } = useLocation();
  //#region styles
  const useStyles = makeStyles((theme) => ({
    backgroundImg: {
      backgroundImage: "url(" + bgImage + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "relative",
      minHeight: "100vh",
    },
    pageSetting: {
      padding: "20px",
      marginLeft: "20px",
      marginRight: "20px",
      paddingTop: "50px",
    },
  }));
  const classes = useStyles();
  //#endregion

  console.log(state.data);

  return (
    <div className={classes.backgroundImg}>
      <Box className={classes.pageSetting} justifyContent="center">
        <Grid container spacing={3} direction={"row"}>
          <Grid item xs={12} md={6}>
            <GameCustomize data={state.data}></GameCustomize>
          </Grid>
          <Grid item xs={12} md={6}>
            <GameIframe
              url={state.data.url}
              userID={state.data.user_id}
              projectID={state.data.project_id}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CustomizePage;
