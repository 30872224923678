import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function FeedbackDialog(props) {
  const { onClose, open, data } = props;

  const onActionBtnClick = () => {
    onClose();
  };

  return (
    <Dialog fullWidth={true} open={open}>
      <DialogTitle>{data.title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{data.msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onActionBtnClick} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FeedbackDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

export default FeedbackDialog;
