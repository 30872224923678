import { Container, Grid,Slider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';


const Input = styled(MuiInput)`
  width: 30px;
  background-Color:rgb(247,221,203);
`;

const useStyles = makeStyles(() => ({
  dynamicSlideStyle: {
    maxWidth: "180px",
  },

}));

const CustomSlider = ({ data, value, itemIndex, onChangeFn }) => {
  const classes = useStyles();

  const handleSliderChange = (event) => {
    //console.log(event.target.value);
    onChangeFn(event.target.value, itemIndex);
  };

  const handleInputChange = (event) => {
    onChangeFn(event.target.value, itemIndex);
  };

  const handleBlur = () => {};

  return (
    <Grid container spacing={3} direction="row" justifyContent={"space-between"} >
      <Grid item xs={5} >
        <Typography style={{ fontSize: "13px" }}>
          {data.display_name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={2} direction={"row"} >
          <Grid item xs={8}>
            <Slider
              size="small"
              className={classes.dynamicSlideStyle}
              min={data.range_lower_bound}
              max={data.range_upper_bound}
              step={(data.range_upper_bound - data.range_lower_bound) / 100}
              value={value}
              onChange={handleSliderChange}
            />
          </Grid>
          <Grid item xs={4} >
            <Input
              style={{fontSize:'12px',padding:'0px'}}
              value={value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                min: data.range_lower_bound,
                max: data.range_upper_bound,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
  </Grid>
  );
};

export default CustomSlider;
